import React, {useState, useEffect} from 'react';
import {navigate} from 'gatsby';
import {useQuery} from '@apollo/react-hooks';
import {GET_USER_WATCH_SETTINGS_QUERY} from '../../graphql/queries';
import Layout from '../../components/layout/layout';
import Button from '../../components/button';
import PricingPackages from '../../components/pricing/pricing-packages';
import Counter from '../../components/header/counter';
import Section from '../../components/section';
import './styles.scss';

const PricingPromo = ({pageContext}: any) => {
  const [annual, setAnnual] = useState(true);
  const [promo, setPromo] = useState(false);
  // const [firstName, setFirstName] = useState('');
  const [currentPackage, setCurrentPackage] = useState('');
  const [currentPackageInterval, setCurrentPackageInterval] = useState('');
  const [isUser, setIsUser] = useState(false);
  const [isPromoUser, setIsPromoUser] = useState<any>(null);
  const {data: hexowatchUserInfo} = useQuery(GET_USER_WATCH_SETTINGS_QUERY, {
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    navigate('/pricing/');
  }, []);
  useEffect(() => {
    if (
      hexowatchUserInfo &&
      hexowatchUserInfo.UserWatchSettings &&
      hexowatchUserInfo.UserWatchSettings.get &&
      hexowatchUserInfo.UserWatchSettings.get.id
    ) {
      setCurrentPackage(hexowatchUserInfo.UserWatchSettings.get.pricing_package);
      setCurrentPackageInterval(hexowatchUserInfo.UserWatchSettings.get.pricing_package_interval);
      setIsUser(true);
      setIsPromoUser(!!hexowatchUserInfo?.User?.get?.promoData?.hexowatch_ltd);
    } else if (
      hexowatchUserInfo &&
      hexowatchUserInfo.UserWatchSettings &&
      hexowatchUserInfo.UserWatchSettings.get &&
      hexowatchUserInfo.UserWatchSettings.get.id === null
    ) {
      setIsUser(false);
      navigate('/login/');
    }
  }, [hexowatchUserInfo]);

  // useEffect(() => {
  // if (userData && userData.User && userData.User.get) {
  //   if (!(userData.User.get.promoData && userData.User.get.promoData.hexowatch_ltd) && !userData.User.get.error) {
  //     navigate('/pricing/');
  //   } else if (userData && userData.User && userData.User.get && userData.User.get.firstName) {
  //     setFirstName(userData.User.get.firstName);
  //   }
  // }
  // }, [userData]);

  // useEffect(() => {
  //   if (!getLTDPromo329()) {
  //     setLTDPromo329('true');
  //   }
  // }, []);

  useEffect(() => {
    // navigate('/pricing/');
    if ((currentPackageInterval !== 'LTD' && currentPackage !== 'FREE' && isUser) || isPromoUser === false) {
      navigate('/pricing/');
    }
  }, []);

  return (
    <div className="content-wrapper">
      <section className="pc-section-dark"></section>
      <section className="pc-section-text">
        <div className="text-center section-content mt-5" style={{maxWidth: '900px', margin: 'auto'}}>
          <div className="text-center" style={{color: 'rgba(0, 0, 0, 0.87)'}}>
            Time flies when you're <strong>automating and monitoring like a pro!</strong>
          </div>
          <div className='mb-4'>It's been <strong>5 incredible years</strong> since we started our scraping and automation journey, and it’s all thanks to you ❤️!</div>
          <div className="text-center" style={{color: 'rgba(0, 0, 0, 0.87)', marginBottom: '20px'}}>
            To show our immense appreciation, you’re invited to an exclusive anniversary promo for our early adopters <strong>(please don’t share on social media)</strong>. 
          </div>
          <div className="text-center" style={{color: 'rgba(0, 0, 0, 0.87)', marginBottom: '20px'}}>
            For the <strong>next 72 hours only</strong>, get your hands on Hexomatic or Hexowatch (or upgrade your current plan) for an
            unbelievable <strong>one-time price of just $99</strong> for lifetime access! 🎉
          </div>
          <Counter
            date="Jun 1 2024 07:00:00 GMT"
            light
            title="DEAL ENDS IN..."
            titleColor="rgba(0, 0, 0, 0.87)"
            titleLarge
          />
        </div>

        <div className="text-center mt-2">
          <Button className="my-2" onClick={() => navigate('/5-year-journey/#pricing')}>
            SECURE YOUR LIFETIME DEAL
          </Button>
        </div>
      </section>
      <Layout promoPage>
        <Section className="pricing-section mb-lg-5">
          <div id="pricing" className="pricing-section mx-auto mt-5">
            {
              //@ts-ignore
              <PricingPackages
                products={pageContext.products}
                interval={annual ? 'annual' : 'monthly'}
                setAnnual={() => {
                  setAnnual(true);
                }}
                promo={promo}
                LTD
                specialLtd
              />
            }
          </div>
        </Section>
      </Layout>
    </div>
  );
};

export default PricingPromo;
